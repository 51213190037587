export enum SentryCategory {
  careThread = 'Care Thread',
  patientProfile = 'Patient Profile',
  photoViewer = 'Photo Viewer',
  serviceWorker = 'Service Worker',
  googleMaps = 'Google Maps',
  fileUpload = 'File Upload',
  mainDasbhoard = 'Main Dashboard',
  authentication = 'Authentication'
}
